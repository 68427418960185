<template>
  <div class="casbee-input">
    <div class="d-flex align-center input-wrapper">
      <div class="input" :class="isItemName && 'item-name'">
        <input
          v-model="valueModel"
          type="text"
          :placeholder="placeholderText"
          class="input-elic"
          :class="{ 'error-message': errorMessage.length > 0, 'input-text': type === 'text', 'input-number': type === 'number' }"
          :style="{ width: width + 'px' }"
          @focus="focus"
          @blur="blur"
          :disabled="disabled"
        />
        
        <img
          v-if="errorMessage.length > 0"
          class="warning-icon"
          width="16"
          height="16"
          src="@/assets/icons/casbee/warning.svg"
        />
      </div>
      <span v-if="label" class="ml-3 label-des">{{ label }}</span>
    </div>
    <p class="error-message" v-if="errorMessage.length > 0">{{ errorMessage }}</p>
  </div>
</template>
<script>
import { toCurrency } from '@/filters/number';
import { formatValue} from '@/concerns/newRegisterData/wijmo.helper';
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth'
export default {
  props: {
    value: [String, Number, null],
    label: {
      type: String,
      default: null,
      required: false,
    },
    errorMessage: {
      type: String,
      default: '',
      required: true,
    },
    width: {
      type: String,
      default: '100',
    },
    type: {
      type: String,
      default: 'number',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isItemName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInputActive: false,
      isActiveCloseIcon: false
    };
  },
  computed: {
    isNumberInput() {
      return this.type === 'number';
    },
    valueModel: {
      get() {
        const valueConvertToHalfWidth = convertNestedFullWidthToHalfWidth(this.value);
        if (this.isNumberInput) {
          return this.isInputActive ? valueConvertToHalfWidth: toCurrency(valueConvertToHalfWidth);
        }
        return valueConvertToHalfWidth;
      },
      set(val) {
        if (this.isNumberInput) {
          let newValue = String(val).replace(/[^\d.]/g, '');
          if (isNaN(newValue)) {
            newValue = null;
          }
          this.$emit('input', newValue);
        }
        this.$emit('input', val);
      },
    },
    iconClose() {
      if (!this.isActiveCloseIcon) {
        return require('@/assets/icons/product/close-input.svg');
      }
      return require('@/assets/icons/product/close-input-active.svg');
    }
  },
  methods: {
    removeError() {
      this.$emit('removeError');
    },
    blur(val) {
      this.isInputActive = false;
      if (this.isNumberInput) {
        let valInput = val.target.value;
        if (
          valInput == null ||
          valInput == undefined ||
          valInput === '' ||
          valInput.trim() == null ||
          valInput.trim() == undefined ||
          valInput.trim() === ''
        ) {
          this.valueModel = null;
        } else {
          const subStringRang = formatValue(valInput)?.includes('-') ? 26 : 25;
          this.valueModel = formatValue(valInput)?.substring(0, subStringRang);
        }
      }
    },
    focus() {
      this.isInputActive = true;
      this.isActiveCloseIcon = false
      this.removeError();
    },
    clearData() {
      this.valueModel = ''
    }
  },
};
</script>
<style lang="scss" scoped>
.casbee-input {
  .input-wrapper {
    .label-des {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      font-family: $notoSanFont;
      color: $monoBlack;

      @include desktop {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .input {
      position: relative;
      &.item-name {
        width: 100%;
        .input-elic {
          width: 100% !important;
        }
        @include desktop {
          width: 300px;
          .input-elic {
            width: 300px !important;
          }
        }
      }
      .input-elic {
        // padding: 0px 6px 0px 6px;
        padding: 7px 16px 9px;
        margin-top: unset;
        margin-bottom: 0;
        background: $monoWhite;
        box-shadow: unset;
        height: 32px;
        border-radius: 4px;
        border: unset;
        text-align: right;
        box-shadow: 0px 2px 6px 0px rgba(160, 181, 186, 0.50) inset, 0px 2px 2px 0px rgba(160, 181, 186, 0.15) inset;

        &:focus {
          border: 2px solid $blueMid;
        }

        @include desktop {
          padding: 0px 8px;
          border: 1px solid $spanishGray;
          box-shadow: unset;
        }

        &.input-text {
          text-align: left;
          color: $monoBlack;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;

          @include desktop {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.05em;
          }
        }
        &.input-number {
          font-family: $notoSanFont;
        }
        &.error-message {
          border: 1px solid $redMid !important;
        }
      }

      .warning-icon {
        position: absolute;
        top: 8px;
        right: 11px;
      }

      .clear-data-input-search {
        position: absolute;
        top: 8px;
        right: 11px;
        cursor: pointer;
      }
    }
  }

  .error-message {
    color: $redMid;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.05em;
  }
}

input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: #cbcbcb;
  overflow: hidden;
}

@include desktop {
  .casbee-input {
    .input-wrapper {
      .input {
        .input-elic {
          height: 32px;
        }
      }
    }
  }
}
</style>
